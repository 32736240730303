/*
 * Holvi custom form adjustments
 */

.custom-radio, .custom-checkbox {
  label {
    font-weight: $font-weight-normal;
  }
}
.custom-checkbox {
  .custom-control-label {
    &::before {
      border-radius: 3px;
    }
  }
  .custom-control-input:not(:checked)~.custom-control-label {
    &::before {
      box-shadow: $input-box-shadow;
    }
  }
}
.custom-radio {
  .custom-control-label,
  .custom-control-input:focus~.custom-control-label {
    &::before {
      box-shadow: $input-box-shadow, inset 0 0 0 3px white;
    }
    &::after {
      background-image: none;
    }
  }
  .custom-control-input:checked~.custom-control-label {
    &::before {
      border-color: $border-color;
    }
    &::after {
      background-image: none;
    }
  }
  .custom-control-input:focus~.custom-control-label {
    &::before {
      border-color: $primary;
    }
  }
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
