@use "sass:color";

/*
 * Holvi form modifications and additions
 */

// align forms in a row to bottom when one of the labels goes to two lines
.form-row {
  align-items: baseline;
}

.form-group {
  // we want more spacing!
  margin-bottom: $hv-spacing-4;
  // align tooltip to form-group
  position: relative;
}

label {
  font-weight: $headings-font-weight;
}

label:not(.form-check-label):not(.custom-control-label),
.label:not(.form-check-label):not(.custom-control-label) {
  font-size: 0.75rem;
}

.label {
  @extend label;
}


span.label{
  white-space: normal;
}

.form-control {
  box-shadow: $input-box-shadow;
  &:focus {
    box-shadow: $input-focus-box-shadow
  }
}

// form-control without input can be used to display static label + content information,
// so when span is used instead of an input we need to remove some styling to make it
// NOT look like an input
span.form-control:not(.dropdown-toggle){
  border-color: transparent !important;
  background: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: none !important;
  &:focus, &:active{
    border-color: transparent !important;
  }
}

// to match two rows of inputs, so that text area fits nicely
// next to two inputs
textarea {
  min-height: 7.25rem;
}

// display a value in fake inputs as form-control
.form-control-value {
  display: flex;
  align-items: baseline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &+ .form-control-value {
    margin-top: $hv-spacing-0_5;
  }
}

.form-control-tag {
  margin-left: $hv-spacing-1_5;
  font-weight: $btn-font-weight;
}

// information text under form field, made a bit smaller
.form-text {
  font-size: $font-size-sm;
}

// for buttons
.form-footer {
  display: flex;
  // use margin to "merge" with content-box bottom margin
  margin-top: $hv-spacing-2;
  margin-bottom: $hv-spacing-4;
  // always show buttons on right side
  justify-content: flex-end;
  .btn + .btn:not(.btn-block) {
    margin-left: $btn-spacing;
  }
}

// errors
.form-group.has-error {
  .form-control {
    border-color: $danger;
    box-shadow: $input-box-shadow-error;
    &:focus {
      box-shadow: $input-focus-box-shadow-error;
    }
  }
}

// angularjs, remove after migration is done
.form-control.ng-invalid {
  border-color: $danger;
  box-shadow: $input-box-shadow-error;
  &:focus {
    box-shadow: $input-focus-box-shadow-error;
  }
}

.error-list {
  list-style: none;
  padding: 0;
  margin: 0;

  .form-control-message-danger {
    color: $text-danger;
  }

  .error-list-item {
    list-style: none;
  }
}

// personalized form error message styles
.form-control .form-control-message {
  position: relative;
  z-index: 0;
}
.form-control .form-control-message,
.form-group .form-control-message {
  padding: 0.3rem 0 0.2rem 0;
}
.form-control-message-danger {
  color: $text-danger;
}
.text-color-invert{
  .form-control-message,
  .form-control-message-danger {
    color: $white !important;
  }
  .form-control::placeholder {
    color: color.adjust($white, $alpha: 0.5) !important;
  }
}

input[type=search] {
  /* clears the 'X' from Internet Explorer */
  &::-ms-clear, &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration, &::-webkit-search-cancel-button,
  &::-webkit-search-results-button, &::-webkit-search-results-decoration {
    display: none;
  }
}

