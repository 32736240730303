.switcher {
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 2rem;
  padding: 0.375rem 0.75rem;

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .switcher__slider:before {
      transform: translateX(1.5rem);
      background-color: $hv-white;
    }

    &:checked + .switcher__slider {
      background-color: $hv-primary;
    }

    &:disabled:not(:checked) + .switcher__slider {
      cursor: initial;
      background-color: $hv-gray-300;
    }
    &:disabled:not(:checked) + .switcher__slider:before {
      background-color: $hv-gray-600;
    }
    &:disabled:is(:checked) + .switcher__slider {
      cursor: initial;
      background-color: $hv-primary-light;
    }
    &:disabled:is(:checked) + .switcher__slider:before {
      background-color: $hv-gray-400;
    }
  }
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $hv-gray-600;
    transition: 0.4s;
    border-radius: 18px;

    &:before {
      position: absolute;
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      left: 0.25rem;
      bottom: 0.25rem;
      background-color: $hv-white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}
