/*
 * Additional styling to input groups
 */

// stop input-group button from sliding to the right
// on entering page by not having transition: all
.input-group {
  &> .form-control,
  &> .custom-select,
  &> .custom-file {
    transition: background-color 0.15s ease-in-out, border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .input-group-text {
    box-shadow: $input-box-shadow;
  }
  // make the input addon also have the border and shadow
  // :focus-within does not work on IE, we can live with that
  &:focus-within {
    .input-group-text {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }
}

  // make the input addon also have the error border and shadow
.form-group.has-error {
  .input-group {
    .input-group-prepend {
      margin-right: -2px;
    }
    .input-group-append {
      margin-left: -2px;
    }
    .input-group-text {
      border-color: $danger;
      box-shadow: $input-box-shadow-error;
    }
    &:focus-within {
      .input-group-text {
        border-color: $danger;
        box-shadow: $input-focus-box-shadow-error;
      }
    }
  }
}

.input-group-text {
  transition: all .15s $holvi-transition-ease-in-out; // like inputs
  min-width: 2.5rem;
  justify-content: center;
}

/* input-group-lg is a bootstrap native class...
  the following changes are made to display the .input-group-text
  in a smaller font-size, otherwise it is massive */
.input-group-lg {
  > .form-control {
    line-height: 2rem;
  }
  > .input-group-prepend, > .input-group-append {
    > .input-group-text{
      font-size: $font-size-base;
    }
  }
}

/* input-group-hover is an additional class that will make the inputs turn blue when hovered....
following the same pattern as table-hover, which does exactly the same for table rows */
.input-group-hover {
  color: inherit !important;
  text-decoration: inherit !important;
  &:focus, &:hover {
    .form-control, .input-group-text {
      background-color: $input-btn-focus-color;
      border-color: $input-focus-border-color;
    }
  }
}

/* input-group-muted is an additional class that will reduce the inputs opacity to 50% */
.input-group-muted {
  opacity: .5;
}
