/*
 * Additional styling to dropdowns
 */

.btn.dropdown-toggle {
  // the caret in buttons, add margin
  &::after {
    margin-left: $hv-spacing-2;
  }
}

.dropdown-divider {
  border-top-width: $border-width;
}

.dropdown-toggle, .input-dropdown.toggle-arrow {
  display: flex;
}

%dropdown-toggle-after,
.dropdown-toggle::after,
.input-dropdown.toggle-arrow::after {
  align-self: baseline;
  font-family: $icon-font-family;
  border: none;
  content: '\E118'; // holvi-fonticons: icon-angle-down
  pointer-events: none;
}

.dropdown-menu {
  width: inherit;
  box-shadow: $dropdown-shadow-h-offset $dropdown-shadow-v-offset $dropdown-shadow-blur $dropdown-shadow-spread $dropdown-shadow-color;
}

.dropdown-item {
  cursor: pointer;
}

.input-dropdown {
  .dropdown-menu {
    width: 100%;
    overflow-y: scroll; // make scroll and now LOOONG list
    max-height: 20rem;
  }
  &.toggle-arrow::after {
    @extend %dropdown-toggle-after;
    position: absolute;
    top: $input-padding-x;// .form-control p-right
    right: $input-padding-x-lg;
  }
  .dropdown-item {
    white-space: normal;
  }
  .dropdown-toggle {
    padding-right: $hv-spacing-3_5; // mock select arrow paddings
  }
}
